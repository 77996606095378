import { Controller } from "@hotwired/stimulus";
import Litepicker from "litepicker";
import 'litepicker/dist/plugins/mobilefriendly';
import { formatDate } from "../helpers";

export default class extends Controller {
  connect() {
    this.picker = new Litepicker({
      element: this.element,
      maxDays: 30,
      singleMode: false,
      numberOfColumns: 2,
      numberOfMonths: 2,
      inlineMode: this.element.getAttribute('data-inline') == "1",
      minDate: this.element.getAttribute('data-min-date'),
      format: "DD/MM/YYYY",
      lang: "FR-fr",
      plugins: ['mobilefriendly']
    });

    this.hiddenElement = document.createElement("input");
    this.hiddenElement.setAttribute("name", this.element.name);
    this.hiddenElement.setAttribute("value", this.element.value);
    this.hiddenElement.setAttribute("type", "hidden");

    this.element.parentElement.append(this.hiddenElement);

    this.picker.on("selected", (date1, date2) => {
      const date = date1.dateInstance;
      this.hiddenElement.value =
        formatDate(date, "yyy-mm-dd") + " - " + formatDate(date2, "yyy-mm-dd");
      if(this.element.getAttribute('data-submit-on-change') == "1"){
        this.element.closest('form').submit();
      }
    });

    this.hiddenElement.value =
      formatDate(this.picker.getStartDate(), "yyy-mm-dd") + " - " + formatDate(this.picker.getEndDate(), "yyy-mm-dd");
  }

  disconnect() {
    this.picker.destroy();
    super.disconnect();
  }
}
