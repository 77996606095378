import ContentLoader from "stimulus-content-loader";
import { get } from "@rails/request.js";

export default class extends ContentLoader {
  static values = {
    xhr: Boolean,
  };

  connect() {
    super.connect();
    this.element[this.identifier] = this;

    this.connectRefreshOnInputChange();
  }

  connectRefreshOnInputChange() {
    if (!this.element.dataset.contentLoaderRefreshOnInputChange) return;

    const inputs_to_listen = document.querySelectorAll(
      this.element.dataset.contentLoaderRefreshOnInputChange
    );
    inputs_to_listen.forEach((element) => {
      element.addEventListener("change", function(){
        console.log('oo');
      });
    });
  }

  async fetch() {
    if (this.xhrValue) {
      await get(this.urlValue)
        .then((response) => response.text)
        .then((html) => {
          this.element.innerHTML = html;
        });
    } else {
      super.fetch();
    }
  }
}
