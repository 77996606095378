import Remote from "stimulus-remote-rails";
import {post} from "@rails/request.js";

export default class extends Remote {
  static targets = ["target", "checkbox"];

  connect() {
    super.connect();
    console.log('autosave');

  }

  async postChange(event) {
    const response = await post(this.element.dataset.action, {query: {atrapalo_id: this.checkboxTarget.id}}) // AJAX request
    const html = await response.text;
    this.element.outerHTML = html;

    const checked_bookings = document.querySelectorAll(".booking-line.checked").length;
    const bookings = document.querySelectorAll(".booking-line").length;
    document.querySelector("#exampleModalLabel").textContent = checked_bookings+"/"+bookings+" reservas";
  }
}
