import { Autocomplete } from "stimulus-autocomplete";
import Autosave from "stimulus-rails-autosave";

export default class extends Autocomplete {
  setValuesToFields(event) {
    const selectedJson = JSON.parse(
      event.detail.selected.getAttribute(
        "data-autocomplete-booking-keys-values"
      )
    );
    for (const key in selectedJson) {
      if (selectedJson.hasOwnProperty(key)) {
        const formInput = this.querySelector(
          "[data-autocomplete-booking-target-name='" + key + "']"
        );
        if (formInput) formInput.value = selectedJson[key];
      }
    }
  }

  connect() {
    super.connect();

    this.element.addEventListener(
      "autocomplete.change",
      this.setValuesToFields
    );
  }
}
